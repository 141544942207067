.loader {
  width: 100%;
  height: 100vh;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  background: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 1s;
}

.svg-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* max-width: 80%; */
}

.loading-text {
  position: absolute;
  display: flex;
  top: 70%;
  font-weight: bold;
  font-size: 1.3rem;
  letter-spacing: 1.2rem;
  color: #fff;
  transform: translate(0.4rem);
  margin-top: 1rem;
  text-align: center;
}

.bg-video__content,
.loading-text {
  opacity: 1;
  transition: opacity 0.5s ease-out;
}

.bg-video__content.fade-out,
.loading-text.fade-out {
  opacity: 0;
}

@media only screen and (max-width: 56.25em) {
  .loader img {
    max-width: 80%;
    height: auto;
  }

  .loading-text {
    top: 60%;
  }
}

@media only screen and (min-width: 200pz) {
  .loader video {
    max-width: 100%;
    height: auto;
  }
}

@media only screen and (min-width: 1080px) {
  .loader video {
    max-width: 80%;
  }
}
